import React, { useEffect } from "react";
import { navigate } from "gatsby";
import SEO from '../components/SEO';
import Layout from '../components/Layout/Layout';

export default function NotFoundPage() {
  useEffect(() => {
    navigate("/"); // redirecting to home page
  }, []);

  return (
    <Layout>
      <SEO title="404: Page not found" />
    </Layout>
  );
}
